import styled from 'styled-components';

const ProjectWrapper = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 120px;
  margin: 0 8rem;
  @media only screen and (max-width: 990px) {
    margin: 0 5rem;
  }
  @media only screen and (max-width: 768px) {
    margin: 0 3rem;
  }
  @media only screen and (max-width: 480px) {
    margin: 0 0.5rem;
  }
  p {
    color: ${({ theme }) => theme.colors.textColor};
    line-height: 1.2;
  }
  strong {
    color: ${({ theme }) => theme.colors.headingColor};
  }
  img {
    max-width: 100%;
    padding: 0.5rem;
  }
  h1,
  h2,
  h3,
  h4 {
    padding-top: 16px;
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.headingColor};
    font-weight: 500;
  }
  p {
    font-size: 20px;
    ${'' /* line-height: 30px; */}
    font-weight: 300;
    ${'' /* margin-bottom: 30px; */}
    color: ${({ theme }) => theme.colors.textColor};
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      ${'' /* line-height: 24px; */}
    }
  }
  ul,
  ol {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.textColor};
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 24px;
    }
    li {
      list-style-type: circle;
      a {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.headingColor};
      }
    }
  }
  ol {
    li {
      list-style-type: decimal;
    }
  }
  .text-center {
    text-align: center;
  }
  .pt-40 {
    padding-top: 2.5rem;
  }
  .pb-30 {
    padding-bottom: 2rem;
  }
  .font-weight-900 {
    font-weight: 900;
  }
  blockquote {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    margin: 0;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 480px) {
      font-size: 24px;
      line-height: 36px;
    }
    .quotations {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 5rem;
      color: ${({ theme }) => theme.colors.headingColor};
      opacity: 0.5;
      z-index: -1;
    }
  }
  hr {
    width: 80%;
    border-color: #f1f1f1;
    border-style: double;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .video-wrapper {
    width: 100%;
    margin-bottom: 2rem;
    video {
      width: 100%;
      height: 100%;
    }
    &.sm {
      justify-content: center;
      text-align: center;
      display: flex;
      video {
        max-width: 450px;
      }
    }
  }
  .caption {
    text-align: center;
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: 0.8rem;
    font-weight: 400;
    opacity: 0.7;
    margin-top: -20px;
  }
  .copyright-source {
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: 0.75rem;
    a {
      color: ${({ theme }) => theme.colors.orange};
    }
  }
  .project_pics {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .glide__track {
    ${'' /* box-shadow: ${({ theme }) => theme.colors.baseBoxShadow}; */}
    border-radius: 10px;
  }
  .grid-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 15px;
    @media only screen and (max-width: 667px) {
      display: block;
      margin-bottom: 15px;
    }
  }
  .info-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.osramGrey};
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    padding: 25px 30px;
    h2,
    h3,
    h4 {
      margin: 0;
      padding-top: 0;
      ${'' /* margin-top: 10px; */}
      font-weight: 400;
      color: ${({ theme }) => theme.colors.osram};
    }
    .info {
      padding: 0.5rem 0;
      p {
        margin: 0;
        font-size: 1rem;
      }
    }
    @media only screen and (max-width: 667px) {
      text-align: center;
      display: block;
      margin-bottom: 15px;
    }
  }
  .info-card {
    line-height: 1;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
    }
    margin-bottom: 15px;
    ${'' /* max-width: 400px; */}
    .info-background {
      height: 100%;
      background-color: ${({ theme }) => theme.colors.osramGrey};
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .img-wrapper {
        height: 250px;
        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          height: 100%;
          width: 100%;
          display: block;
          object-fit: cover;
          border: 0;
        }
      }
      .txt-wrapper {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 15px 30px;
        min-height: 250px;
        h2,
        h3,
        h4 {
          margin: 0;
          margin-top: 10px;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.osram};
        }
        h2 {
          font-size: 1.8rem;
        }
        h3,
        h4 {
          font-size: 1.5rem;
        }
      }
    }
    p {
      margin-top: 20px;
      line-height: 1.5;
    }
  }

  .btn {
    &.primary {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background-color: #ff7b00;
      min-height: 46px;
      min-width: 48px;
      border-radius: 3px;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
      text-transform: capitalize;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 15px;
      padding-right: 15px;
      border: 0;
      transition: all 0.3s ease;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
      height: 40px;
      min-height: auto;
      font-size: 13px;
      border-radius: 4px;
      &:hover {
        box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow};
      }
    }
  }
`;

export default ProjectWrapper;

export const ImageWrapper = styled.div`
  border-radius: 10px;
  img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
  }
`;

export const ImageItem = styled.div`
  border-radius: 10px;
  img {
    max-width: 100%;
    border-radius: 10px;
  }
`;

export const ProductSliderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 50px 0px;
`;

export const ProductItem = styled.div`
  padding: 50px 30px;
  background: #fff;
  border-radius: 10px;
  @media (max-width: 1200px) {
    padding: 40px 20px;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

export const ProductImageWrapper = styled.div`
  min-height: 97px;
`;

export const PrevButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: ${({ theme }) => theme.colors.primary};
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: '';
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(-45deg);
      top: -4px;
      left: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(45deg);
      width: 8px;
      bottom: -6px;
      left: 1px;
    }
  }
`;

export const NextButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: ${({ theme }) => theme.colors.primary};
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: '';
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(45deg);
      top: -4px;
      right: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(-45deg);
      width: 8px;
      bottom: -6px;
      right: 1px;
    }
  }
`;
