import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/Navbar';
import ProjectBanner from 'common/source/containers/ProjectBanner';

import Footer from 'common/source/containers/Footer';
import Container from 'common/source/components/UI/Container';
import GlideCarousel from 'common/source/components/GlideCarousel';
import GlideSlide from 'common/source/components/GlideCarousel/glideSlide';

import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';

import ProjectWrapper, {
  ImageWrapper,
  PrevButton,
  NextButton,
  ImageItem,
  ProductSliderWrapper,
  ProductItem,
  ProductImageWrapper
} from './projects.styles';

import SEO from '../components/SEO';
import config from '../../data/SiteConfig';

const ImageSlider = ({ images }) => {
  const carouselOptions = {
    type: 'carousel',
    autoplay: 4500,
    perView: 1,
    gap: 5,
    animationDuration: 800
  };

  return (
    <GlideCarousel
      carouselSelector='project-carousel'
      options={carouselOptions}
      prevButton={
        <PrevButton>
          <span />
        </PrevButton>
      }
      nextButton={
        <NextButton>
          <span />
        </NextButton>
      }
    >
      <>
        {images.map(({ key, src, alt }) => (
          <GlideSlide key={`project-item-${key}`}>
            <ImageItem>
              <Image
                src={src}
                alt={`Light-up proyecto ${alt}`}
                className=''
                ml='auto'
                mr='auto'
              />
            </ImageItem>
          </GlideSlide>
        ))}
      </>
    </GlideCarousel>
  );
};

const ProductSlider = ({ products }) => {
  const styles = {
    productLogoStyle: {
      ml: 'auto',
      mr: 'auto',
      mb: '25px'
    },
    productNameStyle: {
      fontSize: ['16px', '16px', '18px', '20px'],
      fontWeight: '600',
      color: 'headingColor',
      lineHeight: '1.35',
      textAlign: 'center',
      mb: '17px'
    },
    productDetailsStyle: {
      fontSize: ['15px', '15px', '15px', '16px'],
      color: 'textColor',
      lineHeight: '1.5',
      textAlign: 'center',
      mb: '0'
    }
  };
  const carouselOptions = {
    type: 'carousel',
    autoplay: 8000,
    perView: 4,
    gap: 20,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 3
      },
      767: {
        perView: 2
      },
      500: {
        perView: 1
      }
    }
  };
  return (
    <GlideCarousel
      carouselSelector='products-related-carousel'
      options={carouselOptions}
      prevButton={
        <PrevButton>
          <span />
        </PrevButton>
      }
      nextButton={
        <NextButton>
          <span />
        </NextButton>
      }
    >
      <>
        {products.map(({ frontmatter }) => (
          <GlideSlide key={`project-item-${frontmatter.title}`}>
            <ProductItem>
              <ProductImageWrapper>
                <Image
                  src={frontmatter.thumbnail}
                  alt={`light-up-${frontmatter.title}`}
                  {...styles.productLogoStyle}
                />
              </ProductImageWrapper>
              <Link to={`/producto/${frontmatter.slug}`}>
                <Heading
                  as='h3'
                  content={frontmatter.title}
                  {...styles.productNameStyle}
                />
              </Link>
              <Text
                content={frontmatter.description}
                {...styles.productDetailsStyle}
              />
            </ProductItem>
          </GlideSlide>
        ))}
      </>
    </GlideCarousel>
  );
};

class ProjectTemplate extends React.Component {
  render() {
    const {
      data,
      pageContext,
      row,
      col,
      imageStyle,
      productNameStyle
    } = this.props;
    const { slug } = pageContext;
    const { project, relatedProducts } = data;
    const ProjectProducts = relatedProducts.edges.map(({ node }) => node);

    const postNode = project;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }

    const showSlider = post.slides && post.slides.length > 0;
    const SlideImages =
      post.slides &&
      post.slides.map((slide, index) => ({
        src: slide,
        alt: post.title,
        key: index
      }));
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO postPath={slug} postNode={postNode} postSEO prefix='proyecto' />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <ProjectBanner project={postNode} />
            <Container>
              <ProjectWrapper>
                <Box className='row' {...row}>
                  <Box className='col project_pics' {...col}>
                    {showSlider ? (
                      <ImageSlider images={SlideImages} />
                    ) : (
                      <ImageWrapper>
                        <Image
                          {...imageStyle}
                          src={post.thumbnail}
                          alt={`Light-up proyecto ${post.title}`}
                          className=''
                        />
                      </ImageWrapper>
                    )}
                  </Box>
                  <Box className='col' {...col}>
                    <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
                  </Box>
                </Box>
              </ProjectWrapper>
            </Container>

            {ProjectProducts.length > 1 && (
              <ProductSliderWrapper>
                <Container>
                  <Box className='row' {...row}>
                    <Heading
                      as='h2'
                      content='Algunos productos utilizados'
                      {...productNameStyle}
                    />
                    <ProductSlider products={ProjectProducts} />
                  </Box>
                </Container>
              </ProductSliderWrapper>
            )}
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

ProjectTemplate.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  imageStyle: PropTypes.object,
  productNameStyle: PropTypes.object
};

ProjectTemplate.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    // pt: '15px',
    // pb: '15px',
    alignItems: 'center'
  },
  col: {
    pr: '15px',
    pl: '15px',
    pt: '15px',
    pb: '15px',
    width: ['100%', '100%', '50%', '50%']
  },
  imageStyle: {
    ml: 'auto',
    mr: 'auto'
  },
  productNameStyle: {
    fontSize: ['16px', '18px', '20px', '24px'],
    fontWeight: '600',
    color: 'headingColor',
    lineHeight: '1.35',
    textAlign: 'center',
    mb: '30px'
  }
};

export default ProjectTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ProjectBySlug($slug: String!, $products: [String]!) {
    project: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        title
        slug
        type
        date
        description
        thumbnail
        cover
        # slides
        products
      }
    }
    relatedProducts: allMarkdownRemark(
      filter: { frontmatter: { sku: { in: $products } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          frontmatter {
            title
            slug
            description
            thumbnail
          }
        }
      }
    }
  }
`;
